<template>
  <div id="header" class="header">
    <div class="header-form" v-if="(isDevice == 'mobile' || isDevice == 'tablet') && searchMobileEnabled">
       <div class="form__icon form__icon_left sft-i sft-i-search"></div>
       <div class="header-input">
          <input 
            type="text"
            ref="input-search"
            placeholder="Поиск на сайте"
            v-model="textSearch"
            @keypress.enter="search()"
          />
        </div>
        <div class="form__icon form__icon_right" @click="searchMobileEnabled = false">
          <SvgClose viewBox="0 0 20 20" />
        </div>
    </div>
    <div class="header-main">
      <div id="header-container"  class="uk-container uk-container-large">
        <div 
          class="header-about"
          :class="{ 'active' : !$route.path.indexOf('/about') }"  
        >
          <a href="/about" >О бренде</a>
        </div>
        <div class="header-menu">
          <a href="/">
            <SoftLogo />
          </a>
          <div class="header-search">
            <div class="header-search__button">
              <button
                  @click="search(true)"
                  class="btn-search"
                  aria-label="Поиск"
              >
                <div class="sft-i sft-i-search"></div>
              </button>
              <input 
                  v-if = "isDevice !== 'mobile' && isDevice !== 'tablet'"
                  type="text"
                  ref="input-search"
                  class="input-search"
                  placeholder="Поиск на сайте"
                  v-model="textSearch"
                  @focus="sfocus = true"
                  @blur="sfocus = false"
                  @keypress.enter="search()"
              />
            </div>
          </div>
        </div>
        <div class="header-official">
          <a href="https://www.soft99.co.jp/en/" target="_blank">
            <span>Японский сайт</span>
            <Japan viewBox="0 0 48 30" />
          </a>
        </div>
      </div>
    </div>
    <div class="header-navigator">
      <div class="uk-container uk-container-large">
        <a
          class="navigator-item"
          v-for="(item, index) in menu"
          :key="index"
          :href="item.href"
          :class="{ 'active' : !$route.path.indexOf(item.href) }"
        >
          {{ item.title }}
        </a>
      </div>
    </div>
  </div>
</template>


<script>
import MenuJson from '@/assets/data/menu.json';
import SoftLogo from '@/assets/img/svg/soft99.svg?inline';
import Japan from '@/assets/img/svg/japan.svg?inline';
import SvgClose from '@/assets/img/svg/close.svg?inline';

export default {
  name: 'header-component',
  components: {
    Japan,
    SoftLogo,
    SvgClose
  },
  props: {
    isDevice: null,
  },
  data() {
    return {
      menu: MenuJson,
      sfocus: false,
      textSearch: '',
      searchMobileEnabled: false,
    }
  },
  methods: {
    search(callInput = false) {
      this.searchMobileEnabled = callInput;
      const delay = callInput ? 0 : 100;
      setTimeout(() => {
        let input = this.$refs['input-search'];

        if (input) {
    
          if (Array.isArray(input)) {
            input = input[0]
          }  

          input.focus();
          if (input.value.length > 2 && this.$route.query.text !== input.value) {
            this.$router.push({name: 'search', query: {text: input.value}})
            if (this.$route.name === 'search') this.$router.go()
          }
        }
      }, delay);
    }
  },
}
</script>