import Vue from 'vue'
import Vuex from 'vuex'
import Modal from '@/components/modal-window/storage.js'

Vue.use(Vuex)

const state = {
    name: null,
}

const getters = {
    
}

const mutations = {
    SET_PRODUCT(state, payload) {
        state.name = payload;
    }
}

export function createStore() {
    return new Vuex.Store({
        modules: {
            default: {
                namespaced: true,
                state,
                getters,
                mutations,
            },
            modal: Modal
        }
    })
}


