<template>
  <div id="app">
    <Header :isDevice="device" />  
    <Router-view :isDevice="device" /> 
    <Footer :isDevice="device" />

    <ModalWindow :window-small="modalItem == 'marketplace'">
      <FeedbackForm v-if="modalItem == 'feedback'" />
      <MarketplaceSelector v-if="modalItem == 'marketplace'" />
    </ModalWindow>
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import ModalWindow from './components/modal-window/ModalWindow'
import mixScreenDetect from './mixins/screen-resize.js'
import FeedbackForm from '@/components/feedback-form/FeedbackForm';
import MarketplaceSelector from '@/components/markeplace-selector/MarketplaceSelector';

export default {
  name: 'App',
  components: {
      MarketplaceSelector,
      FeedbackForm,
      Header,
      Footer,
      ModalWindow
  },
  data() {
      return {
          modalItem: null,
      }
  },
  computed: {
      enabled() {
          return this.$store.state.modal.name;
      },
  },
  watch: {
      enabled(value) {
          if (value) {
              this.modalItem = value;
          }
      }
  },
  mixins: [mixScreenDetect],
}
</script>
