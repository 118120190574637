export default {
    data() {
        return {
            device: null,
            devices: {
                laptop: 1024,
                tablet: 920,
                tablet_m: 768,
                mobile: 540,    
            }
        }
    }, 
    methods: {
        RESIZE() {
            this.device = this.GET_DEVICE(this.devices);
        },
        GET_DEVICE(devices) {
            let device = 'desktop';
            if (window) {
                const width = window.innerWidth;
                for (const name in devices) {
                   if (width <= devices[name]) {
                        device = name;
                   }
                }
            }
            return device;    
        }
    },
    async mounted() {
        this.$nextTick(() => {
            this.RESIZE();
            if (window) {    
                window.addEventListener('resize', this.RESIZE);
            }
        });
    },
    created() {
        this.RESIZE();
    },
    beforeDestroy() {
        if (window) {
            window.removeEventListener('resize', this.RESIZE);
        }
    },
}