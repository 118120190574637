import Vue from 'vue';
import VueMeta from 'vue-meta';

import App from './App.vue'
import { createRouter } from './router';
import { createStore } from './storage';

Vue.config.productionTip = false;
Vue.use(VueMeta);

export function createApp() {
  const router = createRouter();
  const store = createStore();

  const app = new Vue({
      router,
      store,
      render: (h) => h(App),
  });

  return {app, router, store};
}