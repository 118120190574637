<template>
    <div class="marketplace">
        <div class="marketplace__title">
            Выберите маркетплейс
        </div>
        <div class="marketplace__content">
            <a :href="links.ozon" target="_blank" class="button button-market button-ozon">
                <IconOzon />
            </a>
            <a :href="links.wildberries" target="_blank" class="button button-market button-wb">
                <IconWildBerries />  
            </a>
        </div>
        <div class="marketplace__retail">
            <a href="/store" target="_blank">
                Розничные магазины на карте
            </a>
        </div>
    </div>
</template>

<script>
import IconOzon from '@/assets/img/svg/ozon.svg?inline';
import IconWildBerries from '@/assets/img/svg/wildberries.svg?inline';
import './marketplace-selector.sass';

export default {
    components: {
        IconOzon,
        IconWildBerries
    },
    data() {
        return {
            ozonDefault: process.env.VUE_APP_LINK_OZON,
            wildDefault: process.env.VUE_APP_LINK_WILDBERRIES
        }
    }, 
    computed: {
        links() {
            const product = this.$store.state.default.name;
            let textOzon = "Soft99";
            let textWB = "Soft99";
            
            if (product && product.title) {
                textOzon = product.title.concat(product.code && product.code !== "<подбор>" ? ` ${product.code}` : "").replace(/[ ()$&+]/g, "+");
                textWB = product.title.replace(/[ ()$&+]/g, "+");
            }

            const ozon = `${this.ozonDefault}&text=${encodeURI(textOzon)}`;
            const wildberries = `${this.wildDefault}&search=${encodeURI(textWB)}`;
            
            return { ozon, wildberries }
        }
    },
}
</script>
