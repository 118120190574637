<template>
  <div id="footer" class="footer">
    <div class="footer-container uk-container uk-container-large">
      <div class="footer-navigation" v-for="(item, index) in menu" :key="index">
        <a class="navigation-header" :href="item.href">
          {{ item.title }}
        </a>

        <div class="navigation-body" v-if="index === 0 && catalog">
          <a
            class="navigation-item"
            v-for="(item, ind) in catalog"
            :key="ind"
            :href="`/catalog#${item.id}`"
          >
            {{ item.title }}
          </a>
        </div>

        <div class="navigation-body" v-if="index === 1 && news">
          <a
            class="navigation-item"
            v-for="(item, ind) in news"
            :key="ind"
            :href="`/articles#${item.id}`"
          >
            {{ item.title }}
          </a>
        </div>

        <div 
          class="navigation-body bottom" 
          v-if="(index === 2 && isDevice == 'desktop') || (index === 1 && isDevice == 'laptop')"
        >
          <button class="button button-white" @click="callModal('feedback')">
            Написать нам
          </button>
        </div>

        <div 
          class="navigation-body bottom" 
          v-if="(index === 3 && isDevice == 'desktop') || (index === 2 && isDevice == 'laptop')"
        >
          <div class="footer-search">
            <div class="footer-search__button">
              <button
                  @click="search($event)"
                  class="btn-search"
                  aria-label="Поиск"
              >
                <div class="sft-i sft-i-search"></div>
              </button>
              <input
                  type="text"
                  ref="input-search"
                  class="input-search"
                  placeholder="Поиск на сайте"
                  @focus="sfocus = true"
                  @blur="sfocus = false"
                  @keypress.enter="search($event)"
              />
            </div>
          </div>
        </div>

        <div 
          class="navigation-body bottom"
          v-if="(index === 3 && isDevice == 'laptop')"
        >
          <a href="/">
            <SoftLogo />
          </a>
          <a class="navigation-about navigation-body_link" href="/about"> О бренде </a>
        </div>
      </div>

      <div class="footer-navigation" v-if="isDevice == 'desktop'" style="width: 80px;">
        <a href="/" style="height: 100px; width: 100px;">
          <SoftLogo />
        </a>

        <div class="navigation-body bottom navigation-body_link">
          <a class="navigation-about" href="/about"> О бренде </a>
        </div>
      </div>

      <template v-if="isDevice == 'tablet' || isDevice == 'mobile'">
        <div class="footer-navigation" >
          <a href="/" style="height: 100px; width: 100px;">
            <SoftLogo />
          </a>
        </div>
        <div class="footer-navigation" >
           <div class="navigation-body navigation-body_link">
              <a class="navigation-about" href="/about"> О бренде </a>
          </div>
        </div>

        <div class="footer-func">
          <div class="navigation-body">
            <button class="button button-white" @click="callModal('feedback')">
              Написать нам
            </button>
          </div>

          <div class="navigation-body">
            <div class="footer-search">
              <div class="footer-search__button">
                <button
                    @click="search($event)"
                    class="btn-search"
                    aria-label="Поиск"
                >
                  <div class="sft-i sft-i-search"></div>
                </button>
                <input
                    type="text"
                    ref="input-search"
                    class="input-search"
                    placeholder="Поиск на сайте"
                    @focus="sfocus = true"
                    @blur="sfocus = false"
                    @keypress.enter="search($event)"
                />
              </div>
            </div>
          </div>
        </div>
      </template>


    </div>
  </div>
</template>


<script>
import MenuJson from "@/assets/data/menu.json";
import SoftLogo from "@/assets/img/svg/soft99.svg?inline";
import utils from "../utils";

export default {
  name: "footer-component",
  components: {
    SoftLogo,
  },
  props: {
    isDevice: null,
  },
  data() {
    return {
      menu: MenuJson,
      catalog: [],
      news: [],
      sfocus: false,
      textSearch: '',
    };
  },
  methods: {
    search() {
      let input = this.$refs['input-search']

      if (input) {
        if (Array.isArray(input)) {
          input = input[0]
        }
        
        input.focus()
        if (input.value.length > 2 && this.$route.query.text !== input.value) {
          this.$router.push({name: 'search', query: {text: input.value}})
          if (this.$route.name === 'search') this.$router.go()
        }
      }
    },
    callModal(prop) {
      this.$store.commit('modal/CALL_MODAL', prop)
    },
    getProductCategories: async function () {
      const { fetchData } = utils();
      const api = "product/category?limit=6&sort=id";

      let response = await fetchData("get", api);
      if (response?.data) {
        this.catalog = response.data;
      }
    },
    getNewsCategories: async function () {
      const { fetchData } = utils();
      const api = "news/category?limit=6";

      let response = await fetchData("get", api);
      if (response?.data) {
        this.news = response.data;
      }
    },
    init() {
      this.getNewsCategories();
      this.getProductCategories();
    },
  },
  beforeMount() {
    this.init();
  },
};
</script>