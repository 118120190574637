import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'main-page',
        component: () => import(/* webpackChunkName: "main-page" */ '../views/MainPage')
    },
    {
        path: '/catalog',
        name: 'catalog',
        component: () => import(/* webpackChunkName: "catalog" */ '../views/Catalog')
    },
    {
       path: '*',
       name: 'not-found',
       component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about-company" */ '../views/AboutCompany')
    },
    {
        path: '/store',
        name: 'store',
        component: () => import(/* webpackChunkName: "store-map" */ '../views/StoreMap')
    },
    {
        path: '/articles',
        name: 'articles',
        component: () => import(/* webpackChunkName: "articles" */ '../views/ArticlesPage')
    },
    {
        path: '/articles/:link',
        name: 'article',
        component: () => import(/* webpackChunkName: "article" */ '../views/SingleArticle')
    },
    {
        path: '/catalog/:link',
        name: 'product',
        component: () => import(/* webpackChunkName: "product" */ '../views/ProductPage')
    },
    {
        path: '/partners',
        name: 'partners',
        component: () => import(/* webpackChunkName: "partners" */ '../views/OurPartners')
    },
    {
        path: '/search',
        name: 'search',
        component: () => import(/* webpackChunkName: "search" */ '../views/SearchPage')
    },
];


export function createRouter() { 
    return new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    })
}